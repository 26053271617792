import config from 'config'

const allowed = ( url: string ) => {
    for ( let i = 0; i < config.allowed.length; i++ ) {
        if ( url.toLowerCase().substring( 0, config.allowed[ i ].length ) === config.allowed[ i ].toLowerCase()) {
            return true
        }
    }

    return false
}

export { allowed }
