import TabeeAuth                      from '@tabee/frontend-auth'
import axios                          from 'axios'
import config                         from 'config'
import { AxiosEvent, TErrors }        from 'models'
import React, { useEffect, useState } from 'react'


import { Center, TabeeLoader }   from 'components'
import { Error, Login, Profile } from 'pages'
import { check, extract }        from 'tools'


const
    Main = () => {
        const
            api = process.env.REACT_APP_API,
            token = localStorage.getItem( 'sso-token' ),
            account = localStorage.getItem( 'sso-account' ),

            { redirect, originalRedirect, signOut, urlParams } = extract.params(),
            addContact = urlParams.get( 'addcontact' ),
            contactType = urlParams.get( 'contact_type' ),
            returnURL = urlParams.get( 'return_url' ),
            allowed = redirect && check.allowed( redirect ),

            [ checking, $checking ] = useState( true ),
            [ view, $view ] = useState( false ),
            [ cards, $cards ] = useState( null ),

            onSuccess = ( token: string | null, account: string | null ) => {
                localStorage.setItem( 'sso-token', token || '' )
                localStorage.setItem( 'sso-account', account ?? '' )

                const
                    re = decodeURIComponent( redirect ),
                    delim = re.indexOf( '?' ) > -1 ? '&' : '?'

                window.location.href = redirect === '/'
                    ? `${redirect}${ addContact ? `?addcontact=${addContact}${ contactType ? `&contact_type=${contactType}` : '' }` : '' }${ returnURL ? `${ addContact ? '&' : '?' }return_url=${returnURL}` : '' }`
                    : `${decodeURIComponent( redirect ?? '' )}${delim}user=${account}&hash=${token}${ returnURL ? `&return_url=${returnURL}` : '' }`
            },

            onFail = () => {
                $checking( false )
            },

            onFailAndClear = () => {
                localStorage.removeItem( 'sso-token' )
                localStorage.removeItem( 'sso-account' )

                onFail()
            },

            onSignOut = () => {
                localStorage.removeItem( 'sso-token' )
                localStorage.removeItem( 'sso-account' )

                TabeeAuth.logOut().then(() => {
                    urlParams.delete( 'signout' )
                    window.location.href = `${window.location.origin}?${urlParams.toString()}`
                })
            },

            onIsAuthorized = () => {
                if ( originalRedirect ) {
                    onSuccess( token, localStorage.getItem( 'sso-account' ))
                } else {
                    axios.get(
                        api + '/api/cards',
                        config.axiosConfig( token )
                    ).then(( event: AxiosEvent ) => {
                        $cards( event.data.results )
                        $checking( false )
                        $view( true )
                    })
                }
            },

            onInit = () => {
                if ( signOut ) {
                    onSignOut()
                } else if ( token ) {
                    axios.get(
                        api + '/api/users',
                        config.axiosConfig( token )
                    ).then(( event: AxiosEvent ) => {
                        ( event.status === 200 )
                            ? event.data.id === account
                                ? onIsAuthorized()
                                : onSignOut()
                            : onFailAndClear()
                    }).catch( onFailAndClear )
                } else {
                    onFail()
                }
            }

        useEffect(() => {
            onInit()
        }, [])

        return (
            <div className="App">
                {
                    checking
                        ? (
                            <Center>
                                <TabeeLoader visible />
                            </Center>
                        )
                        : view
                            ? <Profile cards={cards} onSignOut={onSignOut} />
                            : !allowed
                                ? <Error error={TErrors.invalidRedirect} payload={{ redirect }} />
                                : <Login onSuccess={onSuccess} />
                }
            </div>
        )
    }

export default Main

