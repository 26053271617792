/* eslint-disable no-case-declarations */
import TabeeAuth                  from '@tabee/frontend-auth'
import { Button }                 from 'antd'
import { useEffect, useState }    from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Layout, Center, TabeeLoader, AccountContainer } from 'components'
import { Error }                                         from 'pages'
import { t }                                             from 'tools'

const Validate = () => {
    const
        navigate = useNavigate(),
        { token } = useParams(),
        [ error, $error ] = useState<string | false>( false ),
        [ already, $already ] = useState<boolean>( false ),
        [ success, $success ] = useState<boolean>( false ),

        checkAndValidate = () => {
            if ( TabeeAuth.isReady()) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                TabeeAuth.validateUser( token ).then(( validated: Record<string, any> ) => {
                    if ( !validated.status || validated.status === 'not confirmed' ) {
                        $error( 'validationNotConfirmed' )
                        return
                    }

                    if ([ 'unknownValidationError' ].includes( validated.status )) {
                        $error( validated.status )
                        return
                    }

                    if ( validated.status === 'already confirmed' ) {
                        $already( true )
                    } else {
                        localStorage.setItem( 'sso-token', validated.contact?.token || '' )
                        localStorage.setItem( 'sso-account', validated.contact?.user_id ?? '' )
                    }

                    $success( true )
                })
            } else {
                setTimeout(() => {
                    checkAndValidate()
                }, 200 )
            }
        },

        goRedirect = () => {
            const
                params = new URLSearchParams( window.location.search )

            if ( params.has( 'redirect' )) {
                window.location.href = decodeURIComponent( params.get( 'redirect' ) ?? '/' )
            }

            navigate( '/' )
        },

        toSignIn = () => goRedirect()

    useEffect(() => {
        if ( !token ) {
            $error( 'unknownValidationError' )
            return
        }

        checkAndValidate()
    }, [])

    if ( error ) {
        return (
            <Layout className="validation">
                <Center>

                    <AccountContainer title={t( 'publicCard.SOMETHING_WENT_WRONG' )}>
                        <Error buttonClick={toSignIn} buttonText={t( 'auth.validationButtonText' )} text={t( `auth.${error}` )} />
                    </AccountContainer>
                </Center>

            </Layout>
        )
    }

    if ( success ) {
        return (
            <Layout className="validation">
                <Center>

                    <AccountContainer title={t( already ? 'auth.validateAlreadyValidated' : 'auth.validateSuccess' )}>
                        <Center>
                            <Button
                                size='large'
                                type="primary"
                                style={{
                                    padding: '10px 20px',
                                    height:  'auto'
                                }}
                                onClick={toSignIn}
                            >
                                { t( 'auth.validationButtonText' ) }
                            </Button>
                        </Center>
                    </AccountContainer>
                </Center>

            </Layout>
        )
    }

    return (
        <Layout className="validation">
            <Center>
                <TabeeLoader visible />
            </Center>
        </Layout>
    )

}

export default Validate
