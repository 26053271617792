/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import TabeeAuth                      from '@tabee/frontend-auth'
import { TErrors }                    from 'models'
import React, { useEffect, useState } from 'react'
import { useParams }                  from 'react-router-dom'


import { Error }   from 'pages'
import { extract } from 'tools'

interface AuthEvent {
    data: any
}

interface AuthCheckProps {
    authSet?: boolean
}

const parseSource = ( raw: string | null ) => {
    if ( !raw ) { return {} }

    try {
        const
            parsed = JSON.parse( atob( decodeURIComponent( raw )))

        return parsed
    } catch ( e ) {
        return { error: TErrors.sourceIsMalformed }
    }
}

const AuthCheck = ( props: AuthCheckProps ) => {
    const
        { redirect, urlParams } = extract.params(),
        returnURL = urlParams.get( 'return_url' ),
        source = parseSource( urlParams.get( 'source' )),
        redirect_uri = source.redirect_uri || redirect,
        return_url = source.return_url || returnURL,

        { mode } = useParams(),
        { authSet } = props,
        [ error, $error ] = useState( !!source.error ),
        [ resend, $resend ] = useState( false ),
        [ sending, $sending ] = useState( false ),

        onCodeSent = ( event: any ) => {
            $sending( false )

            if ( !event.status || event.status >= 300 ) {
                if ( event.message.indexOf( 'Already sent' ) > -1 ) {
                    $error( false )
                    $resend( true )
                } else {
                    $error( true )
                    $resend( false )
                }
            } else {
                $error( false )
                $resend( true )
            }
        },

        requestCode = () => {
            $sending( true )

            const
                email = urlParams.get( 'email' )

            TabeeAuth
                .signIn2fa({
                    email,
                    source: {
                        redirect_uri: redirect_uri,
                        return_url:   return_url
                    }
                })
                .then( onCodeSent )
                .catch( onCodeSent )
        },

        handleAuth = () => {

            switch ( mode ) {
                case 'sso':
                    const
                        account = urlParams.get( 'user' ),
                        token = urlParams.get( 'hash' )

                    localStorage.setItem( 'sso-token', token || '' )
                    localStorage.setItem( 'sso-account', account ?? '' )

                    window.location.href = `/${ return_url ? `?return_url=${return_url}` : '' }`
                    break

                case '2fa':
                    // eslint-disable-next-line one-var
                    const
                        email = urlParams.get( 'email' ),
                        code = urlParams.get( 'code' )

                    TabeeAuth.signInCode({
                        email,
                        code
                    })
                        .then(( event: AuthEvent ) => {

                            const
                                token = event?.data?.token,
                                account = event?.data?.user?.id,
                                re = decodeURIComponent( redirect && redirect !== '/' ? redirect : window.location.host ),
                                delim = re.indexOf( '?' ) > -1 ? '&' : '?'

                            localStorage.setItem( 'sso-token', token || '' )
                            localStorage.setItem( 'sso-account', account ?? '' )

                            window.location.href = `https://${re}${delim}user=${account}&hash=${token}${ return_url ? `&return_url=${return_url}` : '' }`
                        })
                        .catch(( e: any ) => {
                            console.log( e )
                            $error( true )
                        })

                    break

                default:
                    //window.location.href = '/'
            }
        }

    useEffect(() => {
        if ( authSet ) {
            handleAuth()
        }
    }, [])

    useEffect(() => {
        if ( authSet ) {
            handleAuth()
        }
    }, [ authSet ])

    return (
        <div className="App">
            {
                error
                    ? (
                        <Error
                            buttonClick = { requestCode }
                            buttonDisabled = { sending }
                            buttonText = 'Resend code'
                            error = { source.error || TErrors.confirmCodeWrong }
                        />
                    )
                    : (
                        resend
                            ? <Error error={ TErrors.alreadySent } />
                            : <></>
                    )
            }
        </div>
    )
}

export default AuthCheck
