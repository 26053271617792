import React from 'react'

import {
    Layout,
    Icons,
    Center,
    AccountContainer,
    SignMethods,
    AccountFooter,
    SocialNetworks,
    Card
} from 'components'
import { t, extract } from 'tools'

import './profile.scss'

interface ICard {
    company: string | undefined,
    company_owner: {
        square_logo: string | null
    },
    owner_user: {
        avatar: string | null
    },
    is_lite: boolean,
    is_archive: boolean,
    parent: string | null,
    id: string,
    first_name: string | null,
    last_name: string | null,
}

interface ProfileProps {
    cards: ICard[] | null,
    onSignOut: () => void
}

const Login = ({ cards, onSignOut }: ProfileProps ) => {
    const
        filteredCards = ( cards || []).filter( card => !card.is_lite && !card.is_archive && !card.parent ),
        token = localStorage.getItem( 'sso-token' ),
        user = localStorage.getItem( 'sso-account' ),
        { urlParams } = extract.params(),
        contact = urlParams.get( 'addcontact' ),
        contactType = urlParams.get( 'contact_type' ),
        returnURL = urlParams.get( 'return_url' )

    return (
        <Layout className="profile">
            <Center>

                <SignMethods noSignUp />

                <AccountContainer
                    description={t( 'sso.multipleIdentities' )}
                    title={t( 'sso.selectIdentity' )}
                >

                    {
                        filteredCards.map( card => (
                            <Card
                                key = { card.id }
                                avatar = { card.company_owner ? card.company_owner.square_logo : card.owner_user.avatar }
                                company = { card.company }
                                fallback = { card.owner_user.avatar }
                                name = { `${card.first_name ?? ''} ${card.last_name ?? ''}`.trim() }

                                onClick = {() => window.location.href = `${process.env.REACT_APP_ID}/u/${card.id}?forced=${user}&hash=${token}${ contact ? `&addcontact=${contact}${ contactType ? `&contact_type=${contactType}` : ''}` : '' }${ returnURL ? `&return_url=${returnURL}&use_card=${card.id}` : ''}`}
                            />
                        ))
                    }

                    <div className="account-methods">

                        <Card
                            className='no-arrow'
                            icon = { <Icons.SignOut extraClassName="navigation-item-icon" /> }
                            name = { t( 'admin.signOut' ) }

                            onClick = { onSignOut }
                        />
                    </div>

                </AccountContainer>

                <AccountFooter>
                    <SocialNetworks />
                </AccountFooter>
            </Center>
        </Layout>
    )
}

export default Login
