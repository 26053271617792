import TabeeAuth                      from '@tabee/frontend-auth'
import { TErrors, TError }            from 'models'
import React, { useState, useEffect } from 'react'
import { useParams }                  from 'react-router-dom'


import { Layout, Center, TabeeLoader } from 'components'
import { Error }                       from 'pages'
import { check, extract }              from 'tools'

const
    Confirm = () => {
        const
            [ error, $error ] = useState<TError | null>( null ),
            params = useParams(),

            { redirect } = extract.params(),
            allowed = redirect && check.allowed( redirect )

        useEffect(() => {
            switch ( params.type ) {
                case 'user':
                    TabeeAuth.confirmUser( params.code ).then(( result: Record<string, unknown> ) => {
                        if ( result?.status === 'confirmed' ) {
                            if ( allowed ) {
                                window.location.href = redirect
                            } else {
                                $error( TErrors.invalidRedirect )
                            }
                        } else {
                            $error( TErrors.confirmCodeWrong )
                        }
                    })
                    break

                case 'link':
                    TabeeAuth.confirmLink( params.code ).then(( result: Record<string, unknown> ) => {
                        if ( result?.result === 'ok' ) {
                            if ( allowed ) {
                                window.location.href = redirect
                            } else {
                                $error( TErrors.invalidRedirect )
                            }
                        } else {
                            $error( TErrors.confirmCodeWrong )
                        }
                    })
                    break

                default:
                    window.location.href = '/'
            }
        }, [])

        return (
            <Layout className="confirm">
                <Center>
                    {
                        error
                            ? <Error error={error} payload={{ redirect }} />
                            : <TabeeLoader visible />
                    }
                </Center>
            </Layout>
        )
    }

export default Confirm
