import classNames from 'classnames'
import React      from 'react'

import './tabee-loader.scss'

interface LoaderProps {
    visible?: boolean
}

const TabeeLoader = ({ visible }: LoaderProps ) => {
    return (
        <div className={classNames( 'public-loader', 'center-container', visible && 'is-visible' )}>
            <svg
                fill="none"
                height="51"
                width="201"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    clipRule="evenodd"
                    d="M181.507 8.51777v4.97783h14.541c.373 0 .533.2722.361.608l-3.615 7.0519c-.171.3329-.613.608-.984.608h-10.303v5.0236h18.326c.379 0 .687.3141.687.6766v6.9147c0 .3737-.306.6766-.687.6766h-27.584c-.38 0-.687-.314-.687-.6766v-3.4573h-.006V4.38385h.006V.926528c0-.373671.305-.676591.687-.676591h27.584c.379 0 .687.314013.687.676591V7.84117c0 .37368-.306.6766-.687.6766h-18.326Zm-38.824 0v4.97783h14.428c.373 0 .534.2722.361.608l-3.615 7.0519c-.171.3329-.613.608-.984.608h-10.19v5.0236h18.213c.379 0 .687.3141.687.6766v6.9147c0 .3737-.305.6766-.687.6766h-27.584c-.379 0-.687-.314-.687-.6766V.926528c0-.373671.305-.676591.687-.676591h27.584c.379 0 .687.314013.687.676591V7.84117c0 .37368-.305.6766-.687.6766h-18.213Zm-117.3251.43657V34.2259c0 .5695-.4684 1.0311-1.0473 1.0311h-8.7254c-.5784 0-1.0473-.4629-1.0473-1.0311V8.95434H2.04645C1.46851 8.95434 1 8.4932 1 7.92241V1.17852C1 .608598 1.46765.146589 2.04639.146589H39.4548c.5779 0 .8337.413003.5717.921791l-3.5863 6.96417c-.2621.50909-.9425.92179-1.5211.92179h-9.5612ZM64.7647 22.7029h-8.7241l4.362-8.6093 4.3621 8.6093Zm4.4626 8.8077H51.5779l-1.3288 2.6228c-.2588.5106-.9351.9227-1.5118.9227h-8.8027c-.5763 0-.8348-.4134-.5764-.9234L56.0838 1.12169c.2588-.510704.9351-.923384 1.5115-.923384h5.613c.5773 0 1.2543.41268 1.5131.923384L81.4471 34.1327c.2584.51-.0001.9234-.5765.9234H72.068c-.5768 0-1.2532-.4122-1.5131-.9253l-1.3276-2.6202ZM108.721 7.62208c1.693 0 3.065 1.33717 3.065 2.98662 0 1.6495-1.372 2.9867-3.065 2.9867v.0094h-9.66V7.62208h9.66Zm.768 13.47472H99.061v6.0681h10.345c.109.0095.219.0144.331.0144 1.872 0 3.389-1.3634 3.389-3.0453 0-1.6818-1.517-3.0453-3.389-3.0453-.083 0-.166.0027-.248.0081Zm13.112 4.2394c0-3.6965-1.965-6.9134-4.863-8.5719 2.235-1.6781 3.687-4.3941 3.687-7.45921 0-4.9537-3.793-8.995686-8.553-9.2177847L99.8134.0260598 89.6794.0004905c-.3735-.0009423-.6763.3018995-.6763.6840635V34.4321c0 .3787.3119.6857.6763.6857h10.134l14.0766-.0052c4.866-.3729 8.711-4.6053 8.711-9.7764Z"
                    fill="#0C2D53"
                    fillRule="evenodd"
                />

                <path
                    clipRule="evenodd"
                    d="M23.8265 50.7388H195.143c.288 0 .628-.207.765-.4725l3.484-6.7938c.134-.2609.002-.4724-.295-.4724H5.24065c-.29774 0-.64563.207-.78229.4724L.9608 50.2663c-.134326.2609-.009155.4725.29514.4725H23.8265Z"
                    fill="#0C2D53"
                    fillRule="evenodd"
                />

                <mask
                    height="8"
                    id="a"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: 'alpha' }}
                    width="200"
                    x="0"
                    y="43"
                >
                    <path
                        clipRule="evenodd"
                        d="M23.8265 50.7388H195.143c.288 0 .628-.207.765-.4725l3.484-6.7938c.134-.2609.002-.4724-.295-.4724H5.24065c-.29774 0-.64563.207-.78229.4724L.9608 50.2663c-.134326.2609-.009155.4725.29514.4725H23.8265Z"
                        fill="#0C2D53"
                        fillRule="evenodd"
                    />
                </mask>

                <g mask="url(#a)">
                    <path
                        className="moving-part"
                        clipRule="evenodd"
                        d="M-13.8139 43H-79.6634c-.2927 0-.6369.207-.7739.4724l-3.5078 6.7938c-.1347.261-.0149.4725.2854.4725H4.77187c.29774 0 .64567-.207.78232-.4725l3.49753-6.7938c.13433-.2609.00919-.4724-.29511-.4724H-13.8139Z"
                        fill="#23B7F9"
                        fillRule="evenodd"
                    />
                </g>
            </svg>
        </div>
    )
}

export default TabeeLoader
