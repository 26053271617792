import React from 'react'

import './tabee-logo.scss'
import tabeeLogo from './tabeeLogo.png'

const TabeeLogo = () => (
    <div className="tabee-logo">
        <img
            alt="Tabee"
            className="image"
            height="32"
            src={tabeeLogo}
            width="180"
        />
    </div>
)

export default TabeeLogo
