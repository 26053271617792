const
    config = {
        allowed: [
            'https://go.tabee.mobi',
            'https://go-dev.tabee.mobi',
            'https://go-stage.tabee.mobi',
            'https://id.tabee.mobi',
            'https://id-dev.tabee.mobi',
            'https://id-stage.tabee.mobi',
            'https://get.tabee.mobi',
            'https://localhost',
            '/',
            '%2F'
        ],

        defaultRedirect: '/',

        axiosConfig: ( token: string | null ) => ({
            validateStatus: null,
            headers:        { Authorization: `Token ${token}` }
        }),

        images: {
            media:          `${process.env.REACT_APP_API}/media`,
            resizer:        '//img.tabee.mobi',
            latestFallback: '//tabee.app/files/placeholder_avatar@2x.jpg'
        },

        links: {
            login:    '/',
            signup:   '/sign-up',
            facebook: 'https://facebook.com',
            twitter:  'https://twitter.com',
            android:  'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',
            ios:      'https://app.adjust.com/rfdji5l?fallback_lp=https%3A%2F%2Ftabee.app&fallback=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1005851014%3Fpt%3D119303076%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.tabee.android&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fsearch%3Fterm%3DTabee%2520Business%2520Card%2520Creator%2520by%2520Tabee%26entity%3Dsoftware%26country%3DUS%26ct%3Dweb%26mt%3D8%26at%3D110lqC%26ls%3D1',

        },
    }

export default config
